import React from "react";
import ImprintFooter from "../../utils/sharedComponents/ImprintFooter";
import { Link } from "react-router-dom";

const PrivacyStatement = () => {
  return (
    <div className="bg-mine">
       <Link to="/">
        <div className="d-flex justify-content-end">
          <button className="btn-smol">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </Link>
      <b>Datenschutz</b>

      <p>
        Informationen über unsere Organisation und Webseite Die Seite wird
        betrieben von der Ruhr Oel GmbH - BP Gelsenkirchen
        Alexander-von-Humboldt-Straße 1 45896 Gelsenkirchen UST-Ident-Nr. (VAT
        REG NO): DE 119364682 Registergericht: AG Gelsenkirchen HRB 7527
      </p>

      <p>
        Tel.: +49 209 366 - 0 Vertreten durch die Geschäftsführung José Luis
        García Galera (Vors.) Holger Blannarsch Michaela Hodyas
      </p>

      <p>
        Vorsitzender des Aufsichtsrates Wolfgang Langhoff Die Ruhr Oel GmbH ist
        eine 100-prozentige Tochtergesellschaft der BP Europa SE. Für Anfragen
        bezüglich Ihrer personenbezogenen Daten oder bei Fragen zu unserer
        Datenschutz-Erklärung, schicken Sie eine Mail an unseren
        Datenschutz-Beauftragten oder schreiben Sie an folgende Adresse: Ruhr
        Oel GmbH Datenschutzbeauftragter Alexander-von-Humboldt-Straße 1 45896
        Gelsenkirchen
      </p>

      <p>
        Die Webseite enthält Links zu Seiten Dritter, für die diese
        Datenschutzregelungen nicht gelten. Informieren Sie sich deshalb bitte
        auf den Internetseiten der anderen Anbieter auch über die dort
        bereitgestellten Datenschutzerklärungen.
      </p>

      <b>Erhebung personenbezogener Daten</b>
      <p>
        Personenbezogene Daten sind Informationen zu Ihrer Identität. Hierunter
        fallen beispielsweise Angaben wie
      </p>

      <ul>
        <li>Name,</li>
        <li>Adresse,</li>
        <li>Telefon-Nummer oder</li>
        <li>E-Mail-Adresse.</li>
      </ul>

      <p>
        Für die Nutzung unserer Webseite und der Online-Unterweisung werden die
        folgenden personenbezogenen Daten zu Ihnen erhoben: Name, Vorname,
        Firma, Geburtsdatum und Foto
      </p>

      <p>
        Diese Daten werden nur erhoben und verarbeitet, um Verpflichtungen aus
        einem Vertrag (gleich welcher Art) zu erfüllen, wenn eine direkte
        vertragliche Beziehung zwischen bp und einem Auftragnehmer oder
        Lieferanten besteht. In solchen Fällen ist die rechtliche Grundlage für
        die Verarbeitung Artikel 6, Abschnitt 1, Buchstabe b) der Allgemeinen
        Datenschutzverordnung ("GDPR"). Darüber hinaus basiert die Verarbeitung
        auf der vorherigen Einwilligung einer Person (eine frei gegebene,
        formell festgelegte und eindeutige positive Handlung, die zu jedem
        späteren Zeitpunkt widerrufen werden kann). In solchen Fällen ist die
        rechtliche Grundlage für die Verarbeitung Artikel 6, Abschnitt 1,
        Buchstabe a) der Allgemeinen Datenschutzverordnung ("GDPR").
      </p>

      <p>
        <b>Verwendung personenbezogener Daten</b>
        <p>
          {" "}
          Die von Ihnen zur Verfügung gestellten personenbezogenen Daten
          verwenden wir zur Ausstellung eines personalisierten Zertifikates nach
          erfolgreichem Abschluss der Online-Unterweisung. Wir verwenden Ihre
          personenbezogenen Daten nicht für Direktmarketing oder zu
          Marktforschungszwecken.
        </p>
      </p>

      <p>
        <b>Veröffentlichung und Weitergabe personenbezogener Daten</b>
        <p>
          Wir behalten uns das Recht vor, Ihre personenbezogenen Daten – soweit
          gesetzlich vorgeschrieben – offenzulegen; ebenso in Fällen, in denen
          wir der Meinung sind, dass eine Offenlegung erforderlich ist, um
          unsere Rechte zu schützen bzw. um einen juristischen Verfahren, einem
          Gerichtsbeschluss, der Anfrage einer Regulierungsbehörde oder
          sonstigen juristischen Auseinandersetzungen gegen B2Mobility
          ordnungsgemäß entsprechen zu können. Zur Erzeugung des
          personalisierten Zertifikates geben wir ihre personenbezogenen Daten
          an einen externen Dienstleister weiter der zur Wahrung geltender
          Datenschutzbestimmungen verpflichtet wurde.
        </p>
      </p>

      <p>
        <b>Datenspeicherung</b>
        <p>
          Wir bewahren Ihre personenbezogenen Daten insoweit wir hierzu
          gesetzlich verpflichtet sind auf, bzw. wie es unter Umständen
          erforderlich ist, um Rechtsansprüche durchzusetzen bzw. uns gegen
          solche zu verteidigen. Daten, die Sie uns hier zur Verfügung stellen
          werden maximal 12 Monate zwecks Zuordnung und Nachverfolgung der
          Vorgänge gespeichert.
        </p>
      </p>

      <p>
        <b>Ihre Rechte</b>{" "}
        <p>
          Sie haben das Recht, Ihre personenbezogenen Daten einzusehen, zu
          korrigieren, zu übertragen, die Datenverarbeitung einzuschränken und
          sie bei unrechtmäßigem Umgang löschen zu lassen. Sie sind berechtigt,
          eine Beschwerde an die für Sie zuständige nationale Aufsichtsbehörde
          für Datenschutz zu richten. Bevor Sie dies tun, könnten Sie jedoch
          überlegen, sich mit Ihrem Anliegen direkt an die Ruhr Oel GmbH - BP
          Gelsenkirchen zu wenden und auf das Beschwerdemanagementverfahren des
          Unternehmens zurückzugreifen.
        </p>
      </p>

      <p>
        <b>Informationen zu Datenverkehr und Cookies</b>{" "}
        <p>
          Unsere Server zeichnen automatisch Daten über Ihren Besuch unserer
          Webseiten auf, basierend auf der IP-Adresse. Diese Internetseite setzt
          nur Cookies ein, die unbedingt erforderlich sind, um Ihnen das
          Navigieren auf dieser Seite zu ermöglichen und Ihre besonderen
          Eigenschaften zu nutzen, z.B. den Zugriff auf gesicherte Bereiche.
          Ohne diese Cookies können einige der von Ihnen gewünschten Dienste
          nicht angeboten werden. Weitere Informationen zum Thema Cookies finden
          Sie hier:{" "}
          <a href="http://www.allaboutcookies.org">
            http://www.allaboutcookies.org
          </a>{" "}
          . Die Rechtsgrundlage für diese Verarbeitung Ihrer Nutzungsdaten ist
          Artikel 6, Absatz 1, lit. f) DSGVO. Unser rechtmäßiges Interesse an
          der Verarbeitung resultiert aus den Anforderungen des
          Arbeitsschutzgesetzes sowie die Sicherstellung der Sicherheit unserer
          informationstechnischen Systeme. Diese Datenschutz-Erklärung ist
          zuletzt am 06.10.2020 geändert worden.
        </p>
      </p>
      <a href="./imprint">
        <ImprintFooter>Impressum</ImprintFooter>
      </a>
    </div>
  );
};

export default PrivacyStatement;
