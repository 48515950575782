import React from "react";
import { Link } from "react-router-dom";
const Imprint = () => {
  return (
    <div className="bg-mine">
      <Link to="/">
        <div className="d-flex justify-content-end">
          <button className="btn-smol">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </Link>

      <b>Rechtlicher Hinweis / Nutzungsbedingung</b>
      <p>
        Wir, die Ruhr Oel GmbH, freuen uns über Ihren Besuch auf unserer Website
        und Ihr Interesse. Im Folgenden möchten wir Ihnen kurz die
        Nutzungsbedingungen unserer Website darlegen.
      </p>

      <b>Anwendungsbereich / Geltung</b>
      <p>
        Die nachfolgenden Allgemeinen Nutzungsbedingungen gelten für den Zugang
        zu und die Nutzung von auf dieser Website bereitgestellten Informationen
        und Dokumenten (im Folgenden 'Inhalte' genannt). Mit Zugriff auf diese
        Webseite erklärt sich der Nutzer mit diesen Allgemeinen
        Nutzungsbedingungen einverstanden.
      </p>

      <b>Gewährleistungs- und Haftungsausschluss</b>
      <p>
        Die Nutzung der Website erfolgt auf eigene Gefahr. Die Ruhr Oel GmbH
        übernimmt keine Gewähr dafür, dass die Website sowie deren Inhalte stets
        zur Verfügung stehen. Die Ruhr Oel GmbH übernimmt insbesondere keine
        Gewähr für die Eignung der Inhalte für einen bestimmten Zweck, deren
        Richtigkeit und Vollständigkeit sowie das Nichtvorhandensein von Viren
        und die fehlerfreie Übertragung.
      </p>

      <p>
        Für Schäden, gleich woraus sie resultieren, haftet die Ruhr Oel GmbH nur
        bei Vorsatz oder grober Fahrlässigkeit ihrer Angestellten oder sonstiger
        Erfüllungsgehilfen. Dies gilt entsprechend im Falle der Verletzung vor-
        oder nebenvertraglicher Pflichten. Auch die Haftung für einfach
        fahrlässige Pflichtverletzung wird ausgeschlossen, soweit diese keine
        vertragswesentlichen Pflichten betreffen. Ersatzansprüche sind auf den
        typischen vorhersehbaren Schaden begrenzt.
      </p>

      <p>
        Soweit wir auf unseren Internetseiten mittels Hyperlink auf
        Internetseiten Dritter verweisen, können wir keine Gewähr für die
        fortwährende Aktualität, Richtigkeit und Vollständigkeit der verlinkten
        Inhalte übernehmen, da diese Inhalte außerhalb unseres
        Verantwortungsbereichs liegen und wir auf die zukünftige Gestaltung
        keinen Einfluss haben. Sollten aus Ihrer Sicht Inhalte gegen geltendes
        Recht verstoßen oder unangemessen sein, teilen Sie uns dies bitte mit.
      </p>

      <b>Rechte an den Inhalten</b>
      <p>
        Sämtliche Rechte (z. B. Copyrights, Urheber-, Nutzungs-, Kennzeichen-
        oder Markenrechte) in Bezug auf die auf der Webseite eingestellten
        Inhalte verbleiben bei der Ruhr Oel GmbH oder ihren Lizenzgebern.
        Jegliche Vervielfältigung, Verarbeitung, Weiterverbreitung und sonstige
        Nutzung der Inhalte ist ausdrücklich verboten, sofern sie nicht vorher
        ausdrücklich genehmigt worden oder gesetzlich gestattet ist.
      </p>

      <b>Formulierungen</b>
      <p>
        Seit dem 18. August 2006 gilt in Deutschland das 'Allgemeine
        Gleichbehandlungsgesetz' (kurz: AGG). "Ziel des Gesetzes ist,
        Benachteiligungen aus Gründen der Rasse oder wegen der ethnischen
        Herkunft, des Geschlechts, der Religion oder Weltanschauung, einer
        Behinderung, des Alters oder der sexuellen Identität zu verhindern oder
        zu beseitigen." (AGG § 1). Die Ruhr Oel GmbH sieht es als Verpflichtung
        an, die Vorgaben des AGG auch auf ihren Webseiten konsequent umzusetzen.
        Sollte in den Inhalten bei Formulierungen nur die weibliche oder nur die
        männliche Form auftauchen, so dient dies nur dazu, den Text lesbarer zu
        halten. Selbstverständlich sind immer alle Menschen (m/w/d)
        gleichermaßen angesprochen.
      </p>

      <b>Datenschutz</b>
      <p>
        Alle Informationen zum Datenschutz und zur Verwendung von Cookies lesen
        Sie im Bereich Datenschutz.
      </p>

      <b>Außergerichtliche Streitbeilegung</b>
      <p>
        Die Ruhr Oel GmbH ist gesetzlich verpflichtet, Sie unabhängig von ihrer
        Teilnahme an einem alternativen Streitbeilegungsverfahren darüber zu
        informieren, dass die EU-Kommission eine Plattform zur
        außergerichtlichen Online-Beilegung von Streitigkeiten zwischen
        Verbrauchern und Unternehmern eingerichtet hat, die über den
        nachfolgenden Link zugänglich ist: http://ec.europa.eu/consumers/odr.
      </p>

      <p>
        Die Ruhr Oel GmbH wird jedoch nicht an einem Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle im Sinne des
        Verbraucherstreitbeilegungsgesetzes (VSBG) teilnehmen und ist dazu auch
        nicht verpflichtet.
      </p>

      <b>Schlussbestimmungen</b>
      <p>
        Es gilt ausschließlich das Recht der Bundesrepublik Deutschland.
        Erfüllungsort ist Gelsenkirchen. Ausschließlicher Gerichtsstand aller
        Ansprüche im Zusammenhang mit diesen Bestimmungen ist ebenfalls
        Gelsenkirchen.
      </p>

      <p>
        Sollte eine Bestimmung dieser Allgemeinen Nutzungsbedingungen unwirksam
        sein oder werden, so wird die Wirksamkeit der Nutzungsbedingungen im
        Übrigen hierdurch nicht berührt. Anstelle der unwirksamen Bedingung gilt
        diejenige Bedingung als maßgeblich, die dem Sinn und Zweck der
        unwirksamen Bedingung am nächsten kommt. Entsprechendes gilt bei
        Unvollständigkeit der Nutzungsbedingungen.
      </p>
    </div>
  );
};

export default Imprint;
