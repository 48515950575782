export const Language = {
  en: {
    title: "User Data",
    subtitle:
      "This information will be used for issuing you the certificate after you have completed the process.",
    username_tag: "First name",
    username: "First name",
    username_muted: "Please enter your first name",
    usersurname_tag: "Last Name",
    usersurname: "Last Name",
    usersurname_muted: "Please enter your last name",
    usercompany_tag: "Company Name",
    usercompany: "Company Name",
    usercompany_muted: "Please add your Company name",
    email_tag: "Email (optional)",
    usercontact: "example@example.com",
    email_muted:
      "Please add your email in the correct format. It will be used for sending you the certificate",
    dob_tag: "Date of Birth",
    userdob: "Date of Birth",
    dob_muted: "Please add your date of birth",
    day_picker_label: "Day:",
    month_picker_label: "Month:",
    year_picker_label: "Year:",
    
  },
  de: {
    title: "Benutzerdaten",
    subtitle:
      "Diese Informationen werden zur Ausstellung des Zertifikats verarbeitet.",
    username_tag: "Vorname",
    username: "Vorname",
    username_muted: "Bitte geben Sie Ihren Voramen ein",
    usersurname_tag: "Nachname",
    usersurname: "Nachname",
    usersurname_muted: "Bitte geben Sie Ihren Nachnamen ein",
    usercompany_tag: "Firma",
    usercompany: "Firmenname",
    usercompany_muted: "Bitte fügen Sie Ihren Firmennamen hinzu",
    email_tag: "Email (optional)",
    usercontact: "example@example.com",
    email_muted:
      "Bitte fügen Sie Ihre E-Mail im richtigen Format hinzu. Sie wird verwendet, um Ihnen die Bescheinigung zu zusenden",
    dob_tag: "Geburtsdatum",
    userdob: "Geburtsdatum",
    dob_muted: "Bitte geben Sie Ihr Geburtsdatum an",
    day_picker_label: "Tag:",
    month_picker_label: "Monat:",
    year_picker_label: "Jahr:",
    
  },
  du: {
    title: "Gebruikersgegevens",
    subtitle:
      "Deze informatie wordt gebruikt om u het certificaat te verstrekken nadat u het proces heeft doorlopen.",
    username_tag: "Voornaam",
    username: "Voornaam",
    username_muted: "Voer uw voornaam in",
    usersurname_tag: "Achternaam",
    usersurname: "Achternaam",
    usersurname_muted: "Voer je achternaam in",
    usercompany_tag: "Bedrijfsnaam",
    usercompany: "Bedrijfsnaam",
    usercompany_muted: "Voeg uw bedrijfsnaam toe",
    email_tag: "E-mail (optioneel)",
    usercontact: "voorbeeld@voorbeeld.com",
    email_muted:
      "Voeg uw e-mail toe in the legal format. Het wordt gebruikt om u het certificaat toe te sturen ",
    dob_tag: "Boat date",
    userdob: "Boat date",
    dob_muted: "Voeg uw boat date toe",
    day_picker_label: "Dag:",
    month_picker_label: "Maand:",
    year_picker_label: "Jaar:",
    
  },

  fr: {
    title: "Données de l'utilisateur",
    subtitle: "Ces informations sont traitées pour délivrer le certificat.",
    username_tag: "Prénom",
    username: "Prénom",
    username_muted: "Veuillez saisir votre prénom",
    usersurname_tag: "Nom de famille",
    usersurname: "Nom de famille",
    usersurname_muted: "Veuillez entrer votre nom de famille",
    usercompany_tag: "Société",
    usercompany: "Nom de la société",
    usercompany_muted: "Veuillez ajouter le nom de votre entreprise",
    email_tag: "Courriel (facultatif)",
    usercontact: "example@example.com",
    email_muted:
      "Veuillez ajouter votre adresse électronique dans le format correct. Il sera utilisé pour vous envoyer le certificat",
    dob_tag: "Date de naissance",
    userdob: "Date de naissance",
    dob_muted: "Veuillez entrer votre date de naissance",
    day_picker_label: "Jour:",
    month_picker_label: "Mois:",
    year_picker_label: "Année:",
    
  },
  es: {
    title: "Datos de usuario",
    subtitle: "Esta información se procesa para emitir el certificado.",
    username_tag: "Nombre de pila",
    username: "Nombre de pila",
    username_muted: "Por favor, introduzca su nombre",
    usersurname_tag: "Apellido",
    usersurname: "Apellido",
    usersurname_muted: "Por favor, introduzca su apellido",
    usercompany_tag: "Compañía",
    usercompany: "Nombre de la empresa",
    usercompany_muted: "Por favor, añada el nombre de su empresa",
    email_tag: "Correo electrónico (opcional)",
    usercontact: "example@example.com",
    email_muted:
      "Por favor, agregue su correo electrónico en el formato correcto. Se usará para enviarle el certificado",
    dob_tag: "Fecha de nacimiento",
    userdob: "Fecha de nacimiento",
    dob_muted: "Por favor, introduzca su fecha de nacimiento",
    day_picker_label: "Día:",
    month_picker_label: "Mes:",
    year_picker_label: "Año:",
    
  },
  pt: {
    title: "Dados do utilizador",
    subtitle: "Esta informação é processada para a emissão do certificado.",
    username_tag: "Primeiro nome",
    username: "Primeiro nome",
    username_muted: "Por favor introduza o seu primeiro nome",
    usersurname_tag: "Apelido",
    usersurname: "Apelido",
    usersurname_muted: "Por favor introduza o seu apelido",
    usercompany_tag: "Empresa",
    usercompany: "Nome da empresa",
    usercompany_muted: "Por favor adicione o nome da sua empresa",
    email_tag: "Email (opcional)",
    usercontact: "example@example.com",
    email_muted:
      "Por favor, adicione o seu e-mail no formato correcto. Será utilizado para lhe enviar o certificado",
    dob_tag: "Data de nascimento",
    userdob: "Data de nascimento",
    dob_muted: "Por favor introduza a sua data de nascimento",
    day_picker_label: "Dia:",
    month_picker_label: "Mês:",
    year_picker_label: "Ano:",
    
  },
  it: {
    title: "Dati utente",
    subtitle:
      "Queste informazioni vengono elaborate per il rilascio del certificato.",
    username_tag: "Nome",
    username: "Nome",
    username_muted: "Inserisci il tuo nome",
    usersurname_tag: "Cognome",
    usersurname: "Cognome",
    usersurname_muted: "Inserisci il tuo cognome",
    usercompany_tag: "Azienda",
    usercompany: "Nome dell'azienda",
    usercompany_muted: "Si prega di aggiungere il nome della vostra azienda",
    email_tag: "E-mail (opzionale)",
    usercontact: "example@example.com",
    email_muted:
      "Si prega di aggiungere la vostra e-mail nel formato corretto. Verrà utilizzato per l'invio del certificato",
    dob_tag: "Data di nascita",
    userdob: "Data di nascita",
    dob_muted: "Inserisci la tua data di nascita",
    day_picker_label: "Giorno:",
    month_picker_label: "Mese:",
    year_picker_label: "Anno:",
    
  },
  po: {
    title: "Dane użytkownika",
    subtitle: "Informacje te są przetwarzane w celu wydania certyfikatu.",
    username_tag: "Imię",
    username: "Imię",
    username_muted: "Proszę wpisać swoje imię",
    usersurname_tag: "Nazwisko",
    usersurname: "Nazwisko",
    usersurname_muted: "Proszę wpisać swoje nazwisko",
    usercompany_tag: "Firma",
    usercompany: "Nazwa firmy",
    usercompany_muted: "Proszę dodać nazwę swojej firmy",
    email_tag: "Email (opcjonalnie)",
    usercontact: "example@example.com",
    email_muted:
      "Proszę dodać swój e-mail w odpowiednim formacie. Zostanie on wykorzystany do przesłania certyfikatu",
    dob_tag: "Data urodzenia",
    userdob: "Data urodzenia",
    dob_muted: "Proszę podać swoją datę urodzenia",
    day_picker_label: "Dzień:",
    month_picker_label: "Miesiąc:",
    year_picker_label: "Rok:",
    
  },
  ru: {
    title: "данные пользователя",
    subtitle: "Эта информация обрабатывается для выдачи сертификата.",
    username_tag: "Имя",
    username: "Имя",
    username_muted: "Пожалуйста введите ваше имя",
    usersurname_tag: "фамилия",
    usersurname: "фамилия",
    usersurname_muted: "Пожалуйста введите свою фамилию",
    usercompany_tag: "Компания",
    usercompany: "Название компании",
    usercompany_muted: "Пожалуйста добавьте название вашей компании",
    email_tag: "Электронная почта (необязательно)",
    usercontact: "example@example.com",
    email_muted:
      "Пожалуйста добавьте ваш адрес электронной почты в правильном формате. Он будет использован для отправки вам сертификата",
    dob_tag: "Дата рождения",
    userdob: "Дата рождения",
    dob_muted: "Пожалуйста укажите дату своего рождения",
    day_picker_label: "День:",
    month_picker_label: "Месяц:",
    year_picker_label: "Год:",
    
  },
  bg: {
    title: "Потребителски данни",
    subtitle: "Тази информация се обработва за издаване на сертификата.",
    username_tag: "Първо име",
    username: "Първо име",
    username_muted: "Моля въведете собственото си име",
    usersurname_tag: "Фамилия",
    usersurname: "Фамилия",
    usersurname_muted: "Моля въведете фамилията си",
    usercompany_tag: "компания",
    usercompany: "Име на компанията",
    usercompany_muted: "Моля добавете името на вашата фирма",
    email_tag: "Имейл (по избор)",
    usercontact: "example@example.com",
    email_muted:
      "Моля добавете вашия имейл в правилния формат. Той ще се използва за изпращане на сертификата",
    dob_tag: "Дата на раждане",
    userdob: "Дата на раждане",
    dob_muted: "Моля въведете датата си на раждане",
    day_picker_label: "Ден:",
    month_picker_label: "Месец:",
    year_picker_label: "Година:",
    
  },
  gr: {
    title: "Δεδομένα χρήστη",
    subtitle:
      "Αυτές οι πληροφορίες υποβάλλονται σε επεξεργασία για την έκδοση του πιστοποιητικού.",
    username_tag: "Ονομα",
    username: "Ονομα",
    username_muted: "Παρακαλώ εισάγετε το ονομα σας",
    usersurname_tag: "Επώνυμο",
    usersurname: "Επώνυμο",
    usersurname_muted: "Παρακαλώ εισάγετε το επώνυμό σας",
    usercompany_tag: "Εταιρία",
    usercompany: "Ονομα εταιρείας",
    usercompany_muted: "Προσθέστε το όνομα της εταιρείας σας",
    email_tag: "Email (προαιρετικό)",
    usercontact: "example@example.com",
    email_muted:
      "Προσθέστε το email σας στη σωστή μορφή. Θα χρησιμοποιηθεί για την αποστολή του πιστοποιητικού",
    dob_tag: "Ημερομηνια γεννησης",
    userdob: "Ημερομηνια γεννησης",
    dob_muted: "Παρακαλώ εισάγετε την ημερομηνία γεννήσεως σας",
    day_picker_label: "Ημέρα:",
    month_picker_label: "Μήνας:",
    year_picker_label: "Έτος:",
    
  },
  hu: {
    title: "Felhasználói adat",
    subtitle:
      "Ezeket az információkat a tanúsítvány kiadása céljából dolgozzuk fel.",
    username_tag: "Keresztnév",
    username: "Keresztnév",
    username_muted: "Kérjük",
    usersurname_tag: " írja be a keresztnevét",
    usersurname: "Vezetéknév",
    usersurname_muted: "Vezetéknév",
    usercompany_tag: "Kérlek írd be a vezetékneved",
    usercompany: "vállalat",
    usercompany_muted: "Cégnév",
    email_tag: "Kérjük",
    usercontact: " adja meg a cég nevét",
    email_muted: "E-mail (választható)",
    dob_tag: "example@example.com",
    userdob: "Kérjük",
    dob_muted:
      " adja meg e-mailjét a megfelelő formátumban. A tanúsítvány elküldésére szolgál",
    day_picker_label: "Nap:",
    month_picker_label: "Hónap:",
    year_picker_label: "Év:",
    
  },
  tr: {
    title: "Kullanıcı bilgisi",
    subtitle: "Bu bilgiler sertifikayı düzenlemek için işlenir.",
    username_tag: "İsim",
    username: "İsim",
    username_muted: "Lütfen isminizi giriniz",
    usersurname_tag: "Soyadı",
    usersurname: "Soyadı",
    usersurname_muted: "Lütfen soyadınızı giriniz",
    usercompany_tag: "şirket",
    usercompany: "Şirket Adı",
    usercompany_muted: "Lütfen şirket adınızı ekleyin",
    email_tag: "E-posta isteğe bağlı)",
    usercontact: "ornek@ornek.com",
    email_muted:
      "Lütfen e-postanızı doğru biçimde ekleyin. Size sertifikayı göndermek için kullanılacaktır",
    dob_tag: "Doğum tarihi",
    userdob: "Doğum tarihi",
    dob_muted: "Lütfen doğum tarihinizi giriniz",
    day_picker_label: "Day:",
    month_picker_label: "Month:",
    year_picker_label: "Year:",
    
  },
  cz: {
    title: "Uživatelská data",
    subtitle: "Tyto informace jsou zpracovány k vydání certifikátu.",
    username_tag: "Jméno",
    username: "Jméno",
    username_muted: "Prosím zadej své křestní jméno",
    usersurname_tag: "Příjmení",
    usersurname: "Příjmení",
    usersurname_muted: "Prosím zadejte své příjmení",
    usercompany_tag: "společnost",
    usercompany: "Jméno společnosti",
    usercompany_muted: "Zadejte prosím název vaší společnosti",
    email_tag: "E-mail (volitelně)",
    usercontact: "example@example.com",
    email_muted:
      "Přidejte svůj e-mail ve správném formátu. Bude použito k zaslání certifikátu",
    dob_tag: "Datum narození",
    userdob: "Datum narození",
    dob_muted: "Prosím vyplňte vaše datum narození",
    day_picker_label: "Den:",
    month_picker_label: "Měsíc:",
    year_picker_label: "Rok:",
    
  },
  ro: {
    title: "Datele utilizatorului",
    subtitle: "Aceste informații sunt procesate pentru a elibera certificatul.",
    username_tag: "Nume",
    username: "Nume",
    username_muted: "Vă rugăm să introduceți prenumele",
    usersurname_tag: "Nume de familie",
    usersurname: "Nume de familie",
    usersurname_muted: "Vă rugăm să introduceți numele de familie",
    usercompany_tag: "companie",
    usercompany: "Numele Companiei",
    usercompany_muted: "Vă rugăm să adăugați numele companiei dvs.",
    email_tag: "E-mail (opțional)",
    usercontact: "exemplu@example.com",
    email_muted:
      "Vă rugăm să adăugați e-mailul în formatul corect. Acesta va fi folosit pentru a vă trimite certificatul",
    dob_tag: "Data de nastere",
    userdob: "Data de nastere",
    dob_muted: "Vă rugăm să introduceți data dvs. de naștere",
    day_picker_label: "Ziua:",
    month_picker_label: "Lună:",
    year_picker_label: "Anul:",
    
  },
  rshr: {
    title: "Кориснички подаци",
    subtitle: "Ови подаци се обрађују за издавање сертификата.",
    username_tag: "Име",
    username: "Име",
    username_muted: "Молимо Вас да унесете своје име",
    usersurname_tag: "Презиме",
    usersurname: "Презиме",
    usersurname_muted: "Унесите презиме",
    usercompany_tag: "компанија",
    usercompany: "Назив фирме",
    usercompany_muted: "Молимо додајте име ваше компаније",
    email_tag: "Е-пошта (опционално)",
    usercontact: "екампле@екампле.цом",
    email_muted:
      "Додајте своју е-пошту у исправном формату. Користиће се за слање сертификата",
    dob_tag: "Датум рођења",
    userdob: "Датум рођења",
    dob_muted: "Унесите свој датум рођења",
    day_picker_label: "Day:",
    month_picker_label: "Month:",
    year_picker_label: "Year:",
    
  },
};
